import React from "react";
import "./domain.css";
import { Button } from "@mui/material";
// Import images
import FacebookIcon from "../../images/f.png";
import InstagramIcon from "../../images/is.png";
import LinkedInIcon from "../../images/l.png";
import TwitterIcon from "../../images/t.png";
import WhatsAppIcon from "../../images/w.png";
import hoursimage from "../../images/111.png";
import textsbaak from "../../images/download.png";
import hoursicon from "../../images/41.png";
import containericon from "../../images/37.png";
import containersmall from "../../images/22.png";
import hosss from "../../images/100.png";
import containersmall24 from "../../images/24.png";
import image12 from "../../images/12.png";
import containersmall26 from "../../images/26.png";
import image13 from "../../images/13.png";
import containersmall28 from "../../images/28.png";
import image16 from "../../images/16.png";
import containersmall33 from "../../images/33.png";
import image14 from "../../images/14.png";
import containersmall36 from "../../images/36.png";
import image15 from "../../images/15.png";
import containersmall366 from "../../images/366.png";
import image38 from "../../images/38.png";
import { useNavigate } from "react-router-dom"; // Import React Router hook


function PublicHome() {
  const navigate = useNavigate(); // Initialize the navigate hook for navigation

  
  const handleLoginClick = () => {
    navigate("/login"); // Navigates to the /login route
  };
  return (
    <div className="background-domain">
     {/* Styled Button */}
     <Button
  variant="contained"
  color="primary"
  onClick={handleLoginClick}
  sx={{
    fontSize: "1rem",
    fontWeight: "bold",
    padding: "10px 20px",
    borderRadius: "8px",
    textTransform: "none",
    zIndex: "9999",
    backgroundColor: "#4CAF50",
    cursor: "pointer",
    position: "absolute",   // Add position: absolute to take it out of normal flow
    right: "20px",          // Position the button 20px from the right edge
    top: "20px",            // Optionally, you can adjust the top value as well
    "&:hover": {
      backgroundColor: "#45a049",
    },
  }}
>
  دخول
</Button>
     
      <div className="social_media">
        {/* Display images */}
        <img src={FacebookIcon} alt="Facebook" className="social-icon" />
        <img src={InstagramIcon} alt="Instagram" className="social-icon" />
        <img src={LinkedInIcon} alt="LinkedIn" className="social-icon" />
        <img src={TwitterIcon} alt="Twitter" className="social-icon" />
        <img src={WhatsAppIcon} alt="WhatsApp" className="social-icon" />
      </div>
      {/* hours-image */}
      <div className="hours-image">
      <img src={hoursimage} alt="hoursimage" className="hoursimage" />
      </div>
      {/* text-sbaak */}
      <div className="text-sbaak">
      <img src={textsbaak} alt="textsbaak" className="text-sbaak" />

      </div>
      
      
      
      {/* container-icon */} {/* hours-icon */}
      <div className="container">
      <img src={containericon} alt="container-icon" className="container-icon" />
      <img src={hoursicon} alt="hoursicon" className="hours-icon" />
      <h1 className="headline">نصنع لك نجاحًا .. لتسبق الجميع</h1>
      <p className="paragraph">
        استعد لنمو متجرك الإلكتروني بأكمله مع سباق، الشريك الذي يقدم حلولًا متكاملة تجعل تجربة التسوق عبر الإنترنت فريدة ولا تُنسى.
      </p>
      
      </div>
       
       {/* container22-icon */} 
      <div className="containersmall">
      <img src={containersmall} alt="container22" className="container22" />
      <h1 className="news">  اخبارنا </h1>
       <h2 className="con22text" >ابق علي اطلاع دائم!</h2>
      <img src={hosss} alt="hosss" className="hosss" />
      </div>




       {/* container24-icon */} 
      <div className="containersmall24">
      <img src={containersmall24} alt="container24" className="container24" />
      <h1 className="GM">  GM </h1>
       <h2 className="AHMED" >ahmed abdelaziz</h2>
      <img src={image12} alt="image12" className="image12" />
      </div>





       {/* container26-icon */} 
      <div className="containersmall26">
      <img src={containersmall26} alt="container26" className="container26" />
      <h1 className="CEO">  CEO </h1>
       <h2 className="SAMI" >SAMI ELMATALYE</h2>
      <img src={image13} alt="image13" className="image13" />
      </div>



       {/* container28-icon */} 
      <div className="containersmall28">
      <img src={containersmall28} alt="container28" className="container28" />
      <h1 className="EMPOLYE">  موظف الشهر  </h1>
       <h2 className="NAMEEM" > AHMED ALI </h2>
      <img src={image16} alt="image16" className="image16" />
      </div>




       {/* container33-icon */} 
      <div className="containersmall33">
      <img src={containersmall33} alt="container33" className="container33" />
      <h1 className="EMPOLYEY">  موظفة الشهر  </h1>
       <h2 className="NAMEEMY" > SALMA AHMED </h2>
      <img src={image14} alt="image14" className="image14" />
      </div>





       {/* container36-icon */} 
      <div className="containersmall36">
      <img src={containersmall36} alt="container36" className="container36" />
      
      <img src={image15} alt="image15" className="image15" />
      </div>





       {/* container37-icon */} 
      <div className="containersmall37">
      <img src={containersmall366} alt="container366" className="container366" />
      
      <img src={image38} alt="image38" className="image38" />
      </div>

    </div>
  );
}

export default PublicHome;
