import React, { useState } from 'react';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';

const Settings = ({ user }) => {
  const [editMode, setEditMode] = useState(false);
  const [updatedPassword, setUpdatedPassword] = useState(user ? user.password : '');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Function to toggle edit mode
  const handleEditClick = () => {
    setEditMode(true);
  };

  // Function to save the updated password
  const handleSaveClick = () => {
    // Check if the user object has a valid employee_id
    if (!user || !user.employee_id) {
      setErrorMessage('Invalid employee ID. Please check the user data.');
      console.log('User object:', user); // Debug the user object
      return;
    }

    if (!updatedPassword.trim()) {
      setErrorMessage('Password cannot be empty');
      return;
    }

    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    // Create a FormData object to send data as form fields
    const formData = new FormData();
    formData.append('employee_id', user.employee_id);
    formData.append('password', updatedPassword);

    // Send a POST request using FormData
    axios
      .post(`https://backendofhr.sapaqagency.com/UpdateEmployeePass.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }, // Set content type for FormData
      })
      .then((response) => {
        if (response.data.status === 'success') {
          setSuccessMessage('Password updated successfully');
          setEditMode(false);
        } else {
          setErrorMessage('Failed to update password: ' + response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage('Error updating password: ' + error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box p={3} style={{ direction: 'rtl', textAlign: 'right' }}>
      <Typography variant="h4" gutterBottom style={{ fontFamily: 'Cairo, sans-serif' }}>
        الإعدادات
      </Typography>
      {user ? (
        <>
          {successMessage && (
            <Typography variant="body2" style={{ color: 'green', fontFamily: 'Cairo, sans-serif' }}>
              {successMessage}
            </Typography>
          )}
          {errorMessage && (
            <Typography variant="body2" style={{ color: 'red', fontFamily: 'Cairo, sans-serif' }}>
              {errorMessage}
            </Typography>
          )}
          <TableContainer component={Paper} style={{ borderRadius: '12px' }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold', fontFamily: 'Cairo, sans-serif', width: '30%' }}>
                    اسم المستخدم
                  </TableCell>
                  <TableCell style={{ fontFamily: 'Cairo, sans-serif', width: '50%' }}>{user.username}</TableCell>
                  <TableCell style={{ width: '20%' }} />
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold', fontFamily: 'Cairo, sans-serif' }}>كلمة المرور</TableCell>
                  <TableCell style={{ fontFamily: 'Cairo, sans-serif' }}>
                    {editMode ? (
                      <TextField
                        value={updatedPassword}
                        onChange={(e) => setUpdatedPassword(e.target.value)}
                        variant="outlined"
                        size="small"
                        type="password"
                        style={{ width: '70%' }}
                      />
                    ) : (
                      '••••••••'
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {editMode ? (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={handleSaveClick}
                        disabled={loading}
                        style={{ fontFamily: 'Cairo, sans-serif' }}
                      >
                        {loading ? 'حفظ...' : 'حفظ'}
                      </Button>
                    ) : (
                      <IconButton onClick={handleEditClick} color="primary">
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography variant="body1" style={{ fontFamily: 'Cairo, sans-serif' }}>
          No user information available.
        </Typography>
      )}
    </Box>
  );
};

export default Settings;
