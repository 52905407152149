import React, { useState, useEffect } from 'react';
import { Typography, Paper, List, ListItem, ListItemText } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const UserOfficialHolidays = () => {
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    fetchHolidays();
  }, []);

  const fetchHolidays = async () => {
    try {
      const response = await fetch('https://backendofhr.sapaqagency.com/holidays.php');
      const data = await response.json();
      setHolidays(data);
    } catch (error) {
      MySwal.fire({
        title: 'خطأ',
        text: 'فشل في جلب البيانات.',
        icon: 'error',
        confirmButtonText: 'حسناً',
        customClass: {
          title: 'font-cairo',
          content: 'font-cairo',
          confirmButton: 'font-cairo',
        }
      });
    }
  };

  return (
    <Paper elevation={3} style={{ padding: '16px', margin: '16px', direction: 'rtl' }}>
      <Typography variant="h5" style={{ fontFamily: 'Cairo, sans-serif', marginBottom: '16px', textAlign: 'center' }}>
        الإجازات الرسمية
      </Typography>
      {holidays.length > 0 ? (
        <List>
          {holidays.map((holiday, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={holiday.name}
                secondary={`من ${holiday.start_date} إلى ${holiday.end_date}`}
                primaryTypographyProps={{ style: { textAlign: 'right', fontFamily: 'Cairo, sans-serif' } }}
                secondaryTypographyProps={{ style: { textAlign: 'right', fontFamily: 'Cairo, sans-serif' } }}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1" style={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center' }}>
          لا يوجد اجازات رسمية بعد
        </Typography>
      )}
    </Paper>
  );
};

export default UserOfficialHolidays;
