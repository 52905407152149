import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid, CircularProgress, Card, CardContent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontFamily: 'Cairo, sans-serif',
  fontWeight: 'bold',
  textAlign: 'center'
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  fontFamily: 'Cairo, sans-serif',
  direction: 'rtl',
  backgroundColor: '#f5f5f5',
  borderRadius: '8px',
  padding: theme.spacing(3),
  textAlign: 'right'
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'center',
  padding: theme.spacing(2),
  fontFamily: 'Cairo, sans-serif'
}));

const FormalText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Cairo, sans-serif',
  marginBottom: theme.spacing(2),
  whiteSpace: 'pre-wrap', // To preserve newlines in text
  backgroundColor: '#e0e0e0',
  padding: theme.spacing(1),
  borderRadius: '4px'
}));

const EditableTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiInputBase-root': {
    fontFamily: 'Cairo, sans-serif',
    fontSize: '1rem',
    padding: '0.5rem'
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Cairo, sans-serif',
    right: 0,
    left: 'auto',
    transformOrigin: 'top right'
  }
}));

const Complaints = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [problems, setProblems] = useState([]);
  const [replies, setReplies] = useState([]);
  const [selectedReply, setSelectedReply] = useState(null);
  const [replyDialogOpen, setReplyDialogOpen] = useState(false);
  const [newProblem, setNewProblem] = useState({
    title: '',
    description: '',
    advice: '',
    formalIntroduction: 'بعد التحية والتقدير، أود التقدم بطلب شكوى بشأن...',
    signature: `مع فائق الاحترام والتقدير،\n${user ? user.name : 'محمد عادل'}`
  });
  const location = useLocation();

  useEffect(() => {
    fetchProblems();
    fetchReplies();
  }, []);

  const fetchProblems = () => {
    axios.get('https://backendofhr.sapaqagency.com/getProblems.php')
      .then(response => {
        if (response.data.success && response.data.data) {
          const queryParams = new URLSearchParams(location.search);
          const username = queryParams.get('username');
          const filteredProblems = response.data.data.filter(problem => problem.username === username);
          setProblems(filteredProblems);
        } else {
          setProblems([]);
        }
      })
      .catch(error => {
        console.error('Error fetching problems:', error);
        setProblems([]); // Ensure problems is always an array
      });
  };

  const fetchReplies = () => {
    axios.get('https://backendofhr.sapaqagency.com/getreplyeis.php')
      .then(response => {
        if (response.data.success && response.data.data) {
          setReplies(response.data.data);
        } else {
          setReplies([]);
        }
      })
      .catch(error => {
        console.error('Error fetching replies:', error);
        setReplies([]); // Ensure replies is always an array
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProblem({ ...newProblem, [name]: value });
  };

  const handleAddProblem = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('username', user.username);
    formData.append('title', newProblem.title);
    formData.append('description', newProblem.description);
    formData.append('advice', newProblem.advice);

    axios.post('https://backendofhr.sapaqagency.com/addProblem.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      setLoading(false);
      if (response.data.success) {
        // Refetch problems to ensure the new problem is included
        fetchProblems();
        setNewProblem({
          title: '',
          description: '',
          advice: '',
          formalIntroduction: 'بعد التحية والتقدير، أود التقدم بطلب شكوى بشأن...',
          signature: `مع فائق الاحترام والتقدير،\n${user ? user.name : 'محمد عادل'}`
        });
        handleClose();
        Swal.fire({
          icon: 'success',
          title: 'تمت إضافة الشكوى بنجاح',
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'خطأ في إضافة الشكوى',
          text: response.data.message
        });
      }
    })
    .catch(error => {
      setLoading(false);
      console.error('Error adding problem:', error);
      Swal.fire({
        icon: 'error',
        title: 'خطأ في إضافة الشكوى',
        text: 'حدث خطأ أثناء إضافة الشكوى. حاول مرة أخرى.'
      });
    });
  };

  const getRepliesForProblem = (problemId) => {
    return replies.filter(reply => reply.problem_id === problemId);
  };

  const handleOpenReplyDialog = (reply) => {
    setSelectedReply(reply);
    setReplyDialogOpen(true);
  };

  const handleCloseReplyDialog = () => {
    setReplyDialogOpen(false);
    setSelectedReply(null);
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom style={{ fontFamily: 'Cairo, sans-serif', textAlign: "right" }}>
        الشكاوي
      </Typography>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpen} style={{ fontFamily: 'Cairo, sans-serif', marginBottom: '16px' }}>
        اضافة شكوى
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <Paper elevation={3}>
          <CustomDialogTitle>إضافة شكوى جديدة</CustomDialogTitle>
          <CustomDialogContent>
            <FormalText variant="body1">{newProblem.formalIntroduction}</FormalText>
            <EditableTextField
              margin="dense"
              label="العنوان"
              type="text"
              fullWidth
              variant="standard"
              name="title"
              value={newProblem.title}
              onChange={handleChange}
            />
            <EditableTextField
              margin="dense"
              label="الوصف"
              type="text"
              fullWidth
              variant="standard"
              multiline
              rows={4}
              name="description"
              value={newProblem.description}
              onChange={handleChange}
            />
            <EditableTextField
              margin="dense"
              label="نصائحك"
              type="text"
              fullWidth
              variant="standard"
              multiline
              rows={4}
              name="advice"
              value={newProblem.advice}
              onChange={handleChange}
            />
            <FormalText variant="body1">{newProblem.signature}</FormalText>
          </CustomDialogContent>
          <CustomDialogActions>
            <Button style={{ fontFamily: "Cairo" }} onClick={handleClose} color="primary">
              إلغاء
            </Button>
            <Button style={{ fontFamily: "Cairo" }} onClick={handleAddProblem} color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'إضافة'}
            </Button>
          </CustomDialogActions>
        </Paper>
      </Dialog>
      <Typography variant="h5" style={{ fontFamily: 'Cairo, sans-serif', textAlign: "right", marginTop: '20px' }}>
        الشكاوي السابقة
      </Typography>
      <br/>
      {problems.length === 0 ? (
        <Typography variant="body1" style={{ fontFamily: 'Cairo, sans-serif', textAlign: "center", marginTop: '20px' }}>
          لا توجد شكاوي سابقة
        </Typography>
      ) : (
        <Grid style={{direction:"rtl"}} container spacing={3}>
          {problems.map(problem => (
            <Grid item xs={12} sm={6} md={4} key={problem.problem_id}>
              <Card>
                <CardContent style={{ textAlign: 'right', direction: 'rtl', fontFamily: 'Cairo, sans-serif' }}>
                  <Typography style={{fontFamily:"cairo"}} variant="h6" component="div">{problem.title}</Typography>
                  <Typography style={{fontFamily:"cairo"}} variant="body2" color="text.secondary">{problem.description}</Typography>
                  <Typography style={{fontFamily:"cairo"}} variant="body2" color="text.secondary"><strong>النصائح:</strong> {problem.advice}</Typography>
                  <Typography style={{fontFamily:"cairo"}} variant="body2" color="text.secondary"><strong>التاريخ:</strong> {moment(problem.date).format('YYYY-MM-DD HH:mm')}</Typography>
                  <Typography style={{fontFamily:"cairo"}} variant="body2" color="text.secondary"><strong>المستخدم:</strong> {problem.username}</Typography>
                  <Typography style={{fontFamily:"cairo"}} variant="body2" color="text.secondary"><strong>الردود:</strong></Typography>
                  {getRepliesForProblem(problem.problem_id).length === 0 ? (
                    <Typography style={{fontFamily:"cairo"}} variant="body2" color="textSecondary">لا توجد ردود</Typography>
                  ) : (
                    getRepliesForProblem(problem.problem_id).map(reply => (
                      <Box key={reply.id} style={{marginBottom: '8px'}}>
                        <Paper elevation={1} style={{ padding: '8px', backgroundColor: '#f5f5f5', borderRadius: '4px', cursor: 'pointer' }} onClick={() => handleOpenReplyDialog(reply)}>
                          <Typography style={{fontFamily:"cairo"}} variant="body2" color="textSecondary"><strong>الموارد البشرية :</strong> {reply.reply}</Typography>
                          <Typography style={{fontFamily:"cairo"}} variant="body2" color="textSecondary">{moment(reply.date).format('YYYY-MM-DD HH:mm')}</Typography>
                        </Paper>
                      </Box>
                    ))
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {selectedReply && (
        <Dialog open={replyDialogOpen} onClose={handleCloseReplyDialog} maxWidth="md" fullWidth>
          <CustomDialogTitle>تفاصيل الرد</CustomDialogTitle>
          <CustomDialogContent>
            <Typography variant="body1" style={{ fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}><strong>الموارد البشرية :</strong> {selectedReply.reply}</Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontFamily: 'Cairo, sans-serif' }}>{moment(selectedReply.date).format('YYYY-MM-DD HH:mm')}</Typography>
          </CustomDialogContent>
          <CustomDialogActions>
            <Button onClick={handleCloseReplyDialog} variant="contained" color="primary" style={{ fontFamily: 'Cairo, sans-serif' }}>إغلاق</Button>
          </CustomDialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default Complaints;
