import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, TextField, Button, Grid, CssBaseline, FormControl, FormLabel } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

// Create RTL cache
const cacheRtl = createCache({
  key: 'muirtl',
  prepend: true,
});

// Create RTL theme
const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: 'Cairo, sans-serif',
  },
});

const LeaveRequestForm = () => {
  const [leaveData, setLeaveData] = useState({
    employeeName: '',
    department: '',
    leaveType: '',
    leaveReason: '',
    startDate: '',
    endDate: '',
  });
  const [username, setUsername] = useState('');
  const [existingLeaveRequests, setExistingLeaveRequests] = useState([]);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const usernameParam = queryParams.get('username');
    setUsername(usernameParam);

    if (usernameParam) {
      fetchLeaveRequests(usernameParam);
    }
  }, [location]);

  const fetchLeaveRequests = async (username) => {
    try {
      const response = await axios.get('https://backendofhr.sapaqagency.com/getleaveRequest.php');
      const leaveRequests = response.data.data;
      const userLeaveRequests = leaveRequests.filter(
        (request) => request.username === username && request.is_accepted === 1
      );
      setExistingLeaveRequests(userLeaveRequests);
    } catch (error) {
      console.error('Error fetching leave requests:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the user has any accepted leave requests
    if (existingLeaveRequests.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: 'لقد تم وصولك للحد الأقصى من الأيام.',
      });
      return;
    }

    const { startDate, endDate } = leaveData;

    // Check if the requested leave is for more than one day
    if (startDate !== endDate) {
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: 'يبنغي عليك طلب يوم واحد فقط.',
      });
      return;
    }

    const formData = { ...leaveData, username };

    axios
      .post('https://backendofhr.sapaqagency.com/postLeaveRequest.php', formData)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'تم إرسال طلب الإجازة بنجاح',
          text: 'تم تقديم طلب الإجازة بنجاح.',
        });
        console.log('Form submitted successfully:', response.data);
      })
      .catch((error) => {
        let errorMessage = 'حدث خطأ ما. حاول مرة أخرى.';
        if (error.response) {
          errorMessage = error.response.data.message || errorMessage;
          console.error('Error response:', error.response.data);
        } else if (error.request) {
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: errorMessage,
        });
      });
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box p={2} style={{ direction: 'rtl', fontFamily: 'Cairo, sans-serif', height: '100%' }}>
          <Typography variant="h5" gutterBottom>طلب إجازة</Typography>
          <Paper style={{ padding: '16px', height: '100%' }}>
            <form onSubmit={handleSubmit} style={{ height: '100%' }}>
              <Grid container spacing={2} style={{ height: '100%' }}>
                <Grid item xs={12}>
                  <TextField
                    label="اسم الموظف"
                    name="employeeName"
                    fullWidth
                    value={leaveData.employeeName}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="القسم"
                    name="department"
                    fullWidth
                    value={leaveData.department}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend" style={{ textAlign: 'right' }}>نوع الإجازة</FormLabel>
                    <TextField
                      name="leaveType"
                      fullWidth
                      value={leaveData.leaveType}
                      onChange={handleChange}
                      inputProps={{ style: { textAlign: 'right' } }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="سبب الإجازة"
                    name="leaveReason"
                    fullWidth
                    value={leaveData.leaveReason}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="تاريخ البدء"
                    name="startDate"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={leaveData.startDate}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="تاريخ الانتهاء"
                    name="endDate"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={leaveData.endDate}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    إرسال
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default LeaveRequestForm;
