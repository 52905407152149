import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import { Box, TextField, IconButton, List, ListItem, ListItemText, Avatar, Grid, Typography, Paper, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { styled } from '@mui/system';

const socket = io('https://my-chat-app-zn13.onrender.com'); // Replace with your WebSocket server URL

const MessageItem = styled(ListItem)(({ theme, isSender }) => ({
  display: 'flex',
  justifyContent: isSender ? 'flex-end' : 'flex-start',
  alignItems: 'center',
  margin: theme.spacing(1, 0),
  textAlign: isSender ? 'right' : 'left',
}));

const MessageText = styled(ListItemText)(({ theme, isSender }) => ({
  backgroundColor: isSender ? '#4CAF50' : '#000', // Green for sender, Black for receiver
  color: isSender ? 'white' : 'lightgray', // Text color adjustment for contrast
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 2),
  maxWidth: '70%',
  wordWrap: 'break-word',
  display: 'inline-block',
  textAlign: 'left',
}));

const ChatContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.background.paper,
  maxHeight: '80vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  flexGrow: 1,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const InputContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const ScrollToBottomButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Chat = ({ senderId = 1, receiverId = 2 }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [employees, setEmployees] = useState({});
  const [offset, setOffset] = useState(0);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);

  const loadMessages = async () => {
    try {
      const scrollPosition = messagesContainerRef.current.scrollHeight - messagesContainerRef.current.scrollTop;
      const response = await axios.get(`https://backendofhr.sapaqagency.com/get_messages.php?sender_id=${senderId}&receiver_id=${receiverId}&limit=20&offset=${offset}`);
      if (response.data.success) {
        setMessages(prevMessages => [...response.data.messages, ...prevMessages]);
        if (response.data.messages.length < 20) {
          setHasMoreMessages(false);
        }
        setOffset(prevOffset => prevOffset + 20);
        setTimeout(() => {
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight - scrollPosition;
        }, 0);
      } else {
        setHasMoreMessages(false);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    loadMessages();
    const fetchEmployees = async () => {
      try {
        const response = await axios.get('https://backendofhr.sapaqagency.com/getemployees.php');
        if (response.data.status === 'success') {
          const employeesMap = {};
          response.data.data.forEach(emp => {
            employeesMap[emp.employee_id] = emp;
          });
          setEmployees(employeesMap);
        }
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();

    socket.on('new_message', (newMessage) => {
      if ((newMessage.sender_id === senderId && newMessage.receiver_id === receiverId) ||
          (newMessage.sender_id === receiverId && newMessage.receiver_id === senderId)) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    });

    return () => {
      socket.off('new_message');
    };
  }, [receiverId, senderId]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleSendMessage = async () => {
    if (!message.trim()) return;

    const newMessage = {
      sender_id: senderId,
      receiver_id: receiverId,
      message,
    };

    socket.emit('send_message', newMessage);
    try {
      const response = await axios.post('https://backendofhr.sapaqagency.com/send_message.php', newMessage, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.data.success) {
        setMessage('');  // Clear the message input after sending
      } else {
        console.error('Error sending message:', response.data.message);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollHeight - scrollTop === clientHeight) {
      setShowScrollToBottom(false);
    } else {
      setShowScrollToBottom(true);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ChatContainer>
      <Typography variant="h6" gutterBottom>
        Chat
      </Typography>
      <MessagesContainer onScroll={handleScroll} ref={messagesContainerRef}>
        {hasMoreMessages && (
          <Button onClick={loadMessages} variant="outlined">
            تحميل المزيد
          </Button>
        )}
        <List>
          {messages.map((msg, index) => {
            const isSender = msg.sender_id === senderId;
            const user = employees[isSender ? senderId : receiverId];
            return (
              <MessageItem key={index} isSender={isSender}>
                {!isSender && <Avatar src={user?.photo ? `https://backendofhr.sapaqagency.com/${user.photo}` : 'https://via.placeholder.com/80'} />}
                <MessageText style={{color:"white",textAlign:"right"}} isSender={isSender}>
                  {msg.message}
                </MessageText>
                {isSender && <Avatar src={user?.photo ? `https://backendofhr.sapaqagency.com/${user.photo}` : 'https://via.placeholder.com/80'} />}
              </MessageItem>
            );
          })}
          <div ref={messagesEndRef} />
        </List>
      </MessagesContainer>
      <InputContainer container spacing={2} alignItems="center">
        <Grid item xs={11}>
          <TextField
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type a message"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleSendMessage} color="primary">
            <SendIcon />
          </IconButton>
        </Grid>
      </InputContainer>
      {showScrollToBottom && (
        <ScrollToBottomButton onClick={scrollToBottom}>
          <ArrowDownwardIcon style={{ bottom: "50%" }} />
        </ScrollToBottomButton>
      )}
    </ChatContainer>
  );
};

export default Chat;
